$theme-colors: (
  "primary": #263544,
);

@import "~rc-slider/assets/index.css";

/* Bootstrap */
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/utilities";

@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/transitions";
