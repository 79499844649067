html,
body,
#root {
	height: 100%;
}

html,
body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: #f2f2f2;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	font-weight: lighter;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

img {
	line-height: 1;
	display: block;
	max-width: 100%;
}

.only-print {
  display: none !important;
}

.pointer {
  cursor: pointer;
}

.gap-1 {
  gap: 1rem;
}

@media print {
  .only-print {
    display: block !important;
  }

  #print-report {
    display: none;
    zoom: 80%;
  }

  #print-report .MuiTable-root {
    zoom: 75%;
  }

  .MuiTextField-root, .MuiToolbar-root {
    display: none !important;
  }

  .avoid-page-break {
    break-after: avoid-page !important;
    break-before: avoid-page !important;
    break-inside: avoid !important;
  }

  .no-print,
  .no-print * {
    display: none !important;
  }

  .print,
  .print * {
    visibility: visible !important;
  }

  .print-avoid-break {
    break-after: page;
    margin: 10mm 7.5mm;
  }

  .print-margin {
    margin: 10mm 7.5mm !important;
  }

  nav {
    display: none !important;
  }

  .cover {
    break-after: page;
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 0;
  }

  .print-mt-3 {
    margin-top: 1rem !important;
  }

  .print-no-padding {
    padding: 0;
  }
}