.border-light-gray {
	border-color: #ced4da !important;
}

.fake-input {
	min-height: calc(1.5em + 0.75rem + 2px);
	line-height: 1.5;
}

.h7 {
	font-size: 0.875rem;
}

.h8 {
	font-size: 0.85rem;
}

.font-weight-semibold {
	font-weight: 500 !important;
}

.overflow-y-auto {
	overflow-y: auto;
}

.row-minus-px-2 {
	margin-left: calc(-15px - 0.5rem);
	margin-right: calc(-15px - 0.5rem);
}

.row-minus-px-3 {
	margin-left: calc(-15px - 1rem);
	margin-right: calc(-15px - 1rem);
}

.line-1 {
	line-height: 1;
}

.word-wrap-anywhere {
	word-wrap: anywhere;
}

.fixed-full {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.top-70 {
	top: 70px;
}

.top-80 {
	top: 80px;
}

.fixed-front {
	z-index: 100;
}

.alert {
	margin-bottom: 0px !important;
}