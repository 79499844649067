.badge-primary {
	color: #4770dd;
	background-color: #e3eafa;
}

.list {
	list-style-type: none;
	padding-left: 1rem;
}

.list li:before {
	content: "\2022";
	color: #a8aad1;
	font-weight: bold; /* If you want it to be bold */
	display: inline-block; /* Needed to add space between the bullet and the text */
	width: 1em; /* Also needed for space (tweak if needed) */
	margin-left: -1em;
	font-size: 1rem;
}

.row-stripped {
	background-color: rgba(0, 0, 0, 0.05);
}

.sidebar-gradient::before {
	background: linear-gradient(88.13deg, #263544 0.58%, #394e63 100%);
	box-shadow: 0px 1px 30px #e1e5ee;
	border-radius: 5px 0px 0px 5px;
	width: 8px;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0;
	content: "";
}

.gradient,
.gradient-button {
	background: #495cd4;
}

.gradient {
	background: linear-gradient(
		35deg,
		rgba(62, 183, 252, 1) 0%,
		rgba(73, 92, 212, 1) 100%
	);
}

.gradient-button {
	background: linear-gradient(90deg, #394e63 -8.05%, #263544 100%);
	transition: 0.2 ease-in-out background;
	letter-spacing: 0.923077px;
	text-transform: uppercase;
	font-weight: bold;
}

.gradient-button:hover,
.gradient-button:active {
	background: linear-gradient(90deg, #4f657b -8.05%, #304254 100%);
}

table a {
	color: #225588;
}